import styled, { css, keyframes } from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;

export const ButtonLabel = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.color.toolTipDark};

  white-space: pre-line;
`;

export const ButtonWrapper = styled.div`
  background: ${({ theme }) => theme.color.toolTipBackground};

  width: 48px;
  height: 24px;
  border-radius: 30px;
  position: relative;

  cursor: pointer;

  transition: background ${({ theme }) => theme.parameter.transition};

  ${({ $active }) =>
    $active &&
    css`
      background: ${({ theme }) => theme.color.accent};
    `};
`;

export const ButtonTextOn = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.color.main};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 6px;
  opacity: 1;
  transition: opacity ${({ theme }) => theme.parameter.transition};
  font-weight: bold;

  ${({ $active }) =>
    !$active &&
    css`
      opacity: 0;
    `};
`;

export const ButtonTextOff = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.color.toolTipDark};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 6px;
  opacity: 0;
  transition: opacity ${({ theme }) => theme.parameter.transition};
  font-weight: bold;

  ${({ $active }) =>
    !$active &&
    css`
      opacity: 1;
    `};
`;

export const ButtonTick = styled.div`
  position: absolute;
  left: 2px;
  top: 2px;
  height: 20px;
  width: 20px;
  background-color: white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  z-index: 1;

  transition: transform ${({ theme }) => theme.parameter.transition};

  ${({ $active }) =>
    $active &&
    css`
      transform: translateX(24px);
    `};
`;
