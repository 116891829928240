export const enumTabLeftSideNavigator = {
  PRODUCT: 'PRODUCT',
  LAYERS: 'LAYERS',
  PRINT_AREAS: 'PRINT_AREAS',
  CREATE_VIEWER: 'CREATE_VIEWER',
};

export const enumTabRightSideNavigator = {
  IMAGE: 'IMAGE',
  DESIGNS: 'DESIGNS',
  TEXT: 'TEXT',
  AIIMAGE: 'AIIMAGE',
};

export const enumViewMode = {
  THREE: 'THREE',
  FABRIC: 'FABRIC',
  THREE_AND_FABRIC: 'THREE_AND_FABRIC',
};

export const enumSrcDst = {
  EMPTY: 'EMPTY',
  UPLOAD: 'UPLOAD',
  LIB_IMG: 'LIB_IMG',
};

export const enumQuality = {
  VERY_HIGH_QUALITY: 'VERY_HIGH_QUALITY',
  HIGH_QUALITY: 'HIGH_QUALITY',
  MEDIUM_QUALITY: 'MEDIUM_QUALITY',
  LOW_QUALITY: 'LOW_QUALITY',
  VERY_LOW_QUALITY: 'VERY_LOW_QUALITY',
};

export const enumLayerType = {
  IMAGE: 'IMAGE',
  TEXT: 'TEXT',
  PASTE_FROM_CLIPBOARD: 'PASTE_FROM_CLIPBOARD',
  IMPORT_SINGLE_GRAFIC: 'IMPORT_SINGLE_GRAFIC',
  EDIT_PRINT_AREA: 'EDIT_PRINT_AREA',
  S_RECT: 'S_RECT',
};

export const enumSelectLayerType = {
  ID: 'ID',
  INDEX: 'INDEX',
  CLEAR: 'CLEAR',
};

export const enumWorkspaceModes = {
  STANDARD: 'STANDARD',
  ADD_NEW_OBJECT: 'ADD_NEW_OBJECT',
  PAN: 'PAN',
  EDIT_PRINT_AREAS: 'EDIT_PRINT_AREAS',
};

export const enumBackgroundRemovalTools = {
  BACKGROUND_COLOR: 'backgroundColor',
  BRUSH: 'brush',
  ERASER: 'eraser',
  COLOR_RANGE_PICKER: 'colorRangePicker',
  ZOOM: 'zoom',
  PAN: 'pan',
  NONE: 'none',
};

export const enumThreeDragTool = {
  POSITION: 'POSITION',
  SCALE_WIDTH_HEIGHT: 'SCALE_WIDTH_HEIGHT',
  SCALE_WIDTH: 'SCALE_WIDTH',
  SCALE_HEIGHT: 'SCALE_HEIGHT',
  ROTATE: 'ROTATE',
  NONE: 'NONE',
};

export const enumSubscriptionPlan = {
  PERSONAL_FREE: 'Personal FREE',
  PERSONAL: 'Personal',
};
