export const bluePinkTheme = {
  color: {
    logoStart: '#f8d7e6',
    logoEnd: '#e1808f',

    webBackground:
      'linear-gradient(128deg, rgba(0,110,145,1) 0%, rgba(225,128,143,1) 100%)',
    backgroundLayoutAccent: 'rgba(0,0,0,0.5)',
    backgroundLayoutLight: 'rgba(0,0,0,0.3)',
    backgroundLayoutLightOverlay: 'rgba(0,0,0,0.3)',
    backgroundLayoutHoverNotSelectedDark: 'rgba(0,0,0,0.5)',
    backgroundLayoutHoverNotSelected: 'rgba(0,0,0,0.3)',
    backgroundLayoutHoverSelected: 'rgba(214,128,143,0.7)',
    backgroundLayoutOptions: 'rgba(0,0,0,0.2)',
    backgroundLayoutNotSelectedLight: 'rgba(0,0,0,0.1)',
    backgroundDark: '#233E4F',
    backgroundLeftWindow: 'rgba(10, 12, 17, 0.95)',

    main: '#ffffff',
    contrastMain: '#ffffff',
    accent: '#D6808F',
    light: '#B2B2B2',
    dark: '#8f6c79',

    scrollbarThumbColor: 'rgba(214,128,143,0.4)',
    scrollbarThumbColorHovered: 'rgba(214,128,143,0.7)',

    toolTipBackground: '#e9e9e9',
    toolTipBackgroundElement: '#ffffff',
    toolTipVeryDark: '#383838',
    toolTipDark: '#7D7D7D',
    toolTipLight: '#C2C2C2',

    error: '#F10C0C',
    warning: '#FFD400',
    warningHover: '#E8C100',
    warningContrast: '#000000',
    canvasToolsBackground: 'rgba(0,0,0,0.15)',
    fabricSlider: '#e9e9e9',

    selecboxHover: 'rgba(0,0,0,0.2)',
    canvasModeObject: 'rgba(0, 0, 0, 0.7)',
  },
  parameter: {
    transition: '0.3s',
  },
};

export const blueGreenTheme = {
  color: {
    logoStart: '#a2eeda',
    logoEnd: '#2d9a8d',

    webBackground:
      'linear-gradient(128deg, rgba(73,56,176,1) 0%, rgba(46,214,164,1) 100%)',
    backgroundLayoutAccent: 'rgba(0,0,0,0.5)',
    backgroundLayoutLight: 'rgba(0,0,0,0.3)',
    backgroundLayoutLightOverlay: 'rgba(0,0,0,0.3)',
    backgroundLayoutHoverNotSelectedDark: 'rgba(0,0,0,0.5)',
    backgroundLayoutHoverNotSelected: 'rgba(0,0,0,0.3)',
    backgroundLayoutHoverSelected: 'rgba(48,207,165,0.7)',
    backgroundLayoutOptions: 'rgba(0,0,0,0.2)',
    backgroundLayoutNotSelectedLight: 'rgba(0,0,0,0.1)',
    backgroundDark: '#2a2e5e',
    backgroundLeftWindow: 'rgba(10, 12, 17, 0.95)',

    main: '#ffffff',
    contrastMain: '#ffffff',
    accent: '#30CFA5',
    light: '#B2B2B2',
    dark: '#2D9A8D',

    scrollbarThumbColor: 'rgba(48,207,165,0.8)',
    scrollbarThumbColorHovered: 'rgba(48,207,165,1.0)',

    toolTipBackground: '#e9e9e9',
    toolTipBackgroundElement: '#ffffff',
    toolTipVeryDark: '#383838',
    toolTipDark: '#7D7D7D',
    toolTipLight: '#C2C2C2',

    error: '#F10C0C',
    warning: '#FFD400',
    warningHover: '#E8C100',
    warningContrast: '#000000',
    canvasToolsBackground: 'rgba(0,0,0,0.15)',
    fabricSlider: '#e9e9e9',

    selecboxHover: 'rgba(0,0,0,0.2)',
    canvasModeObject: 'rgba(0, 0, 0, 0.7)',
  },
  parameter: {
    transition: '0.3s',
  },
};

export const magickBlueTheme = {
  color: {
    logoStart: '#a2eeda',
    logoEnd: '#2d9a8d',

    webBackground:
      'linear-gradient(128deg, rgba(17, 17, 17, 1) 0%, rgba(0, 5, 30, 1) 100%)',
    backgroundLayoutAccent: 'rgba(0,0,0,0.5)',
    backgroundLayoutLight: 'rgba(0,0,0,0.3)',
    backgroundLayoutLightOverlay: 'rgba(0,0,0,0.3)',
    backgroundLayoutHoverNotSelectedDark: 'rgba(255, 255, 255, 0.3)',
    backgroundLayoutHoverNotSelected: 'rgba(255, 255, 255, 0.2)',
    backgroundLayoutHoverSelected: 'rgba(255,255,255,1.0)',
    backgroundLayoutOptions: 'rgba(255, 255, 255, 0.1)',
    backgroundLayoutNotSelectedLight: 'rgba(255, 255, 255, 0.1)',
    backgroundDark: '#2a2e5e',
    backgroundLeftWindow: 'rgba(10, 12, 17, 0.95)',

    main: '#ffffff',
    contrastMain: '#000000',
    accent: '#30CFA5',
    light: '#B2B2B2',
    dark: '#2D9A8D',

    scrollbarThumbColor: 'rgba(48,207,165,0.8)',
    scrollbarThumbColorHovered: 'rgba(48,207,165,1.0)',

    toolTipBackground: '#e9e9e9',
    toolTipBackgroundElement: '#ffffff',
    toolTipVeryDark: '#383838',
    toolTipDark: '#7D7D7D',
    toolTipLight: '#C2C2C2',

    error: '#F10C0C',
    warning: '#FFD400',
    warningHover: '#E8C100',
    warningContrast: '#000000',

    canvasToolsBackground: 'rgba(0,0,0,0.15)',
    fabricSlider: '#e9e9e9',

    selecboxHover: 'rgba(0, 0, 0, 0.2)',
    canvasModeObject: 'rgba(48,207,165,0.7)',
  },
  parameter: {
    transition: '0.3s',
  },
};

export const DarkTheme = {
  color: {
    logoStart: '#a2eeda',
    logoEnd: '#2d9a8d',

    webBackground:
      'linear-gradient(128deg, rgba(17, 17, 17, 1) 0%, rgba(0, 5, 30, 1) 100%)',
    backgroundLayoutAccent: 'rgb(27, 27, 27)',
    backgroundLayoutLight: 'rgb(27, 27, 27)',
    backgroundLayoutLightOverlay: 'rgb(19, 19, 19)',
    backgroundLayoutHoverNotSelectedDark: 'rgba(255, 255, 255, 0.3)',
    backgroundLayoutHoverNotSelected: 'rgba(255, 255, 255, 0.2)',
    backgroundLayoutHoverSelected: 'rgba(255,255,255,1.0)',
    backgroundLayoutOptions: 'rgba(255, 255, 255, 0.1)',
    backgroundLayoutNotSelectedLight: 'rgba(255, 255, 255, 0.1)',
    backgroundDark: '#2a2e5e',
    backgroundLeftWindow: 'rgb(54, 54, 54)',

    main: '#ffffff',
    contrastMain: '#000000',
    accent: '#30CFA5',
    light: '#B2B2B2',
    dark: '#2D9A8D',

    scrollbarThumbColor: 'rgba(48,207,165,0.8)',
    scrollbarThumbColorHovered: 'rgba(48,207,165,1.0)',

    toolTipBackground: '#e9e9e9',
    toolTipBackgroundElement: '#ffffff',
    toolTipVeryDark: '#383838',
    toolTipDark: '#7D7D7D',
    toolTipLight: '#C2C2C2',

    error: '#F10C0C',
    warning: '#FFD400',
    warningHover: '#E8C100',
    warningContrast: '#000000',

    canvasToolsBackground: 'rgb(27, 27, 27)',
    fabricSlider: '#e9e9e9',

    selecboxHover: 'rgba(0, 0, 0, 0.2)',
    canvasModeObject: 'rgba(48,207,165,0.7)',
  },
  parameter: {
    transition: '0.3s',
  },
};

export const LightTheme = {
  color: {
    logoStart: '#a2eeda',
    logoEnd: '#2d9a8d',

    webBackground:
      'linear-gradient(128deg, rgba(17, 17, 17, 1) 0%, rgba(0, 5, 30, 1) 100%)',
    backgroundLayoutAccent: 'rgb(230, 230, 230)',
    backgroundLayoutLight: 'rgb(230, 230, 230)',
    backgroundLayoutLightOverlay: 'rgb(255, 255, 255)',
    backgroundLayoutHoverNotSelectedDark: 'rgba(0, 0, 0, 0.3)',
    backgroundLayoutHoverNotSelected: 'rgba(0, 0, 0, 0.37)',
    backgroundLayoutHoverSelected: 'rgba(0, 0, 0,0.5)',
    backgroundLayoutOptions: 'rgba(0, 0, 0, 0.1)',
    backgroundLayoutNotSelectedLight: 'rgba(0, 0, 0, 0.1)',
    backgroundDark: '#2a2e5e',
    backgroundLeftWindow: 'rgb(230, 230, 230)',

    main: '#000000',
    contrastMain: '#ffffff',
    accent: '#30CFA5',
    light: '#B2B2B2',
    dark: '#2D9A8D',

    scrollbarThumbColor: 'rgba(48,207,165,0.8)',
    scrollbarThumbColorHovered: 'rgba(48,207,165,1.0)',

    toolTipBackground: '#e9e9e9',
    toolTipBackgroundElement: '#ffffff',
    toolTipVeryDark: '#383838',
    toolTipDark: '#7D7D7D',
    toolTipLight: '#C2C2C2',

    error: '#F10C0C',
    warning: '#FFD400',
    warningHover: '#E8C100',
    warningContrast: '#000000',

    canvasToolsBackground: 'rgb(230, 230, 230)',
    fabricSlider: 'rgba(0, 0, 0, 0.35)',

    selecboxHover: 'rgba(0, 0, 0, 0.2)',
    canvasModeObject: 'rgba(48,207,165,0.7)',
  },
  parameter: {
    transition: '0.3s',
  },
};
