import {
  blueGreenTheme,
  bluePinkTheme,
  magickBlueTheme,
  DarkTheme,
  LightTheme,
} from 'constant/theme';

export const themeOptions = [
  {
    value: 0,
    label: 'Transparent Light - Light Blue',
    theme: magickBlueTheme,
    image: true,
  },
  {
    value: 1,
    label: 'Transparent Light - Dark Pink',
    theme: bluePinkTheme,
    image: false,
  },
  {
    value: 2,
    label: 'Transparent Light - Dark Blue',
    theme: blueGreenTheme,
    image: false,
  },

  {
    value: 3,
    label: 'Dark - Light Blue',
    theme: DarkTheme,
    image: false,
  },
  {
    value: 4,
    label: 'Light - Light Blue',
    theme: LightTheme,
    image: false,
  },
];

export const themeBackgroundsList = [
  {
    id: 60,
    image: false,
    image_url: '',
    image_url_thumb: '',
    backgroundColor: '#ffffff',
  },
  {
    id: 59,
    image: true,
    image_url: 'bcgImg59.png',
    image_url_thumb: 'bcgImg59_thumb.png',
    backgroundColor: '#00051e',
  },

  {
    id: 0,
    image: true,
    image_url: 'bcgImg0.png',
    image_url_thumb: 'bcgImg0_thumb.png',
    backgroundColor: '#000000',
  },

  {
    id: 1,
    image: true,
    image_url: 'bcgImg1.png',
    image_url_thumb: 'bcgImg1_thumb.png',
    backgroundColor: '#000000',
  },

  {
    id: 2,
    image: true,
    image_url: 'bcgImg2.png',
    image_url_thumb: 'bcgImg2_thumb.png',
    backgroundColor: '#000000',
  },

  {
    id: 3,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg3.png',
    image_url_thumb: 'bcgImg3_thumb.png',
  },
  {
    id: 4,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg4.png',
    image_url_thumb: 'bcgImg4_thumb.png',
  },
  {
    id: 5,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg5.png',
    image_url_thumb: 'bcgImg5_thumb.png',
  },
  {
    id: 6,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg6.png',
    image_url_thumb: 'bcgImg6_thumb.png',
  },

  {
    id: 7,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg7.png',
    image_url_thumb: 'bcgImg7_thumb.png',
  },
  {
    id: 8,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg8.png',
    image_url_thumb: 'bcgImg8_thumb.png',
  },
  {
    id: 9,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg9.png',
    image_url_thumb: 'bcgImg9_thumb.png',
  },
  {
    id: 10,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg10.png',
    image_url_thumb: 'bcgImg10_thumb.png',
  },

  {
    id: 11,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg11.png',
    image_url_thumb: 'bcgImg11_thumb.png',
  },
  {
    id: 12,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg12.png',
    image_url_thumb: 'bcgImg12_thumb.png',
  },
  {
    id: 13,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg13.png',
    image_url_thumb: 'bcgImg13_thumb.png',
  },
  {
    id: 14,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg14.png',
    image_url_thumb: 'bcgImg14_thumb.png',
  },
  {
    id: 15,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg15.png',
    image_url_thumb: 'bcgImg15_thumb.png',
  },
  {
    id: 16,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg16.png',
    image_url_thumb: 'bcgImg16_thumb.png',
  },
  {
    id: 17,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg17.png',
    image_url_thumb: 'bcgImg17_thumb.png',
  },
  {
    id: 18,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg18.png',
    image_url_thumb: 'bcgImg18_thumb.png',
  },
  {
    id: 19,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg19.png',
    image_url_thumb: 'bcgImg19_thumb.png',
  },
  {
    id: 20,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg20.png',
    image_url_thumb: 'bcgImg20_thumb.png',
  },
  {
    id: 21,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg21.png',
    image_url_thumb: 'bcgImg21_thumb.png',
  },
  {
    id: 22,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg22.png',
    image_url_thumb: 'bcgImg22_thumb.png',
  },
  {
    id: 23,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg23.png',
    image_url_thumb: 'bcgImg23_thumb.png',
  },
  {
    id: 25,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg25.png',
    image_url_thumb: 'bcgImg25_thumb.png',
  },
  {
    id: 26,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg26.png',
    image_url_thumb: 'bcgImg26_thumb.png',
  },
  {
    id: 27,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg27.png',
    image_url_thumb: 'bcgImg27_thumb.png',
  },
  {
    id: 28,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg28.png',
    image_url_thumb: 'bcgImg28_thumb.png',
  },
  {
    id: 29,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg29.png',
    image_url_thumb: 'bcgImg29_thumb.png',
  },
  {
    id: 30,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg30.png',
    image_url_thumb: 'bcgImg30_thumb.png',
  },
  {
    id: 31,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg31.png',
    image_url_thumb: 'bcgImg31_thumb.png',
  },
  {
    id: 32,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg32.png',
    image_url_thumb: 'bcgImg32_thumb.png',
  },
  {
    id: 33,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg33.png',
    image_url_thumb: 'bcgImg33_thumb.png',
  },
  {
    id: 34,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg34.png',
    image_url_thumb: 'bcgImg34_thumb.png',
  },
  {
    id: 35,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg35.png',
    image_url_thumb: 'bcgImg35_thumb.png',
  },
  {
    id: 36,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg36.png',
    image_url_thumb: 'bcgImg36_thumb.png',
  },
  {
    id: 37,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg37.png',
    image_url_thumb: 'bcgImg37_thumb.png',
  },
  {
    id: 38,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg38.png',
    image_url_thumb: 'bcgImg38_thumb.png',
  },
  {
    id: 39,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg39.png',
    image_url_thumb: 'bcgImg39_thumb.png',
  },
  {
    id: 40,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg40.png',
    image_url_thumb: 'bcgImg40_thumb.png',
  },
  {
    id: 41,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg41.png',
    image_url_thumb: 'bcgImg41_thumb.png',
  },
  {
    id: 42,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg42.png',
    image_url_thumb: 'bcgImg42_thumb.png',
  },
  {
    id: 43,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg43.png',
    image_url_thumb: 'bcgImg43_thumb.png',
  },
  {
    id: 44,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg44.png',
    image_url_thumb: 'bcgImg44_thumb.png',
  },
  {
    id: 45,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg45.png',
    image_url_thumb: 'bcgImg45_thumb.png',
  },
  {
    id: 46,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg46.png',
    image_url_thumb: 'bcgImg46_thumb.png',
  },
  {
    id: 47,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg47.png',
    image_url_thumb: 'bcgImg47_thumb.png',
  },
  {
    id: 48,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg48.png',
    image_url_thumb: 'bcgImg48_thumb.png',
  },
  {
    id: 49,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg49.png',
    image_url_thumb: 'bcgImg49_thumb.png',
  },
  {
    id: 50,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg50.png',
    image_url_thumb: 'bcgImg50_thumb.png',
  },
  {
    id: 51,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg51.png',
    image_url_thumb: 'bcgImg51_thumb.png',
  },
  {
    id: 52,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg52.png',
    image_url_thumb: 'bcgImg52_thumb.png',
  },

  {
    id: 53,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg53.png',
    image_url_thumb: 'bcgImg53_thumb.png',
  },
  {
    id: 54,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg54.png',
    image_url_thumb: 'bcgImg54_thumb.png',
  },
  {
    id: 55,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg55.png',
    image_url_thumb: 'bcgImg55_thumb.png',
  },
  {
    id: 56,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg56.png',
    image_url_thumb: 'bcgImg56_thumb.png',
  },
  {
    id: 57,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg57.png',
    image_url_thumb: 'bcgImg57_thumb.png',
  },
  {
    id: 58,
    image: true,
    backgroundColor: '#000000',
    image_url: 'bcgImg58.png',
    image_url_thumb: 'bcgImg58_thumb.png',
  },
];
