import { fabric } from 'fabric';
import { enumLayerType, enumWorkspaceModes, enumSrcDst } from 'store/enums';
import { qualityOptions } from 'store/lists';
import { v4 as uuidv4 } from 'uuid';
import { clipPath } from './WorkspaceTools';

import FontFaceObserver from 'fontfaceobserver';
import { fontList } from 'store/apiFontResponse';

import { filtersList } from 'store/lists';

import { addFilter } from 'layout/configurator/rightSide/workspace/components/canvasTools/ImageFilters';
import { setState, getState } from 'store/store';
import { addNewLayer } from 'layout/configurator/leftSide/tabsLeftSide/tabArea/testLayer4/utils/utils';

import { mmToPx } from 'layout/configurator/rightSide/workspace/components/three/scene/Model/printArea/utils';

import { calculatePixelsToMilimiters } from 'layout/configurator/rightSide/workspace/modals/utils/utilsExportLayers';

import _ from 'lodash';

export function zoomToScale(zoomValue) {
  const minZoomValue = 0.3;
  const maxZoomValue = 20;

  // Odwracamy operację, aby uzyskać wartość dla slidera na podstawie aktualnego zoomu
  return (
    ((zoomValue - minZoomValue) / (maxZoomValue - minZoomValue)) * (500 - 1) + 1
  );
}

export function scaleToZoom(scaleValue) {
  const minZoomValue = 0.3;
  const maxZoomValue = 20;

  // Skalujemy liniowo wartość 1-200 na zakres zoomu 0.01-20
  return (
    ((scaleValue - 1) / (500 - 1)) * (maxZoomValue - minZoomValue) +
    minZoomValue
  );
}

export const sortLayers = (fabricRef, layersIDList) => {
  const alwaysAtStartIds = [
    'grid',
    'labelsTop',
    'labelsLeft',
    'workspaceBorder',
    'backgroundImages',
  ];
  const alwaysAtEndIds = [
    'positioningLineVertical',
    'positioningLineHorizontal',
    'printBorder',
  ];

  // Przenoszenie stałych warstw na początek
  alwaysAtStartIds.reverse().forEach((id) => {
    const obj = fabricRef.getObjects().find((o) => o.id === id);
    if (obj) {
      fabricRef.sendToBack(obj);
    }
  });

  // Przesuwanie elementów z group1 na ich pozycje, zgodnie z kolejnością w tablicy
  const helper = [...layersIDList];
  helper.reverse().forEach((item) => {
    const obj = fabricRef.getObjects().find((o) => o.id === item.id);
    if (obj) {
      fabricRef.bringToFront(obj);
    }
  });

  // Przenoszenie stałych warstw na koniec
  alwaysAtEndIds.forEach((id) => {
    const obj = fabricRef.getObjects().find((o) => o.id === id);
    if (obj) {
      fabricRef.bringToFront(obj);
    }
  });

  // Przenoszenie wszystkich elementów zaczynających się od "clipPath_" na koniec
  fabricRef.getObjects().forEach((obj) => {
    if (obj.id && obj.id.startsWith('clipPath_')) {
      fabricRef.bringToFront(obj);
    }
  });
};

export const selectObjectsByLayers = (
  fabricCanvas,
  selectedLayers,
  textureFabric = false
) => {
  let selectionObjectsFabricList = [];
  fabricCanvas.getObjects().forEach(function (object) {
    if (selectedLayers.includes(object.id)) {
      selectionObjectsFabricList.push(object);
    }
  });

  fabricCanvas.discardActiveObject();
  if (selectionObjectsFabricList.length > 1) {
    const newSelection = new fabric.ActiveSelection(
      selectionObjectsFabricList,
      {
        canvas: fabricCanvas,
      }
    );

    if (
      getState().workspacesMode.mode === enumWorkspaceModes.EDIT_PRINT_AREAS
    ) {
      if (textureFabric) {
        newSelection.setControlsVisibility({
          mrCustom: false,
          mbCustom: false,
          brCustom: false,
          mtr: false,
          unlock: false,
          lock: false,
          clone: false,
          deleteControl: false,

          mtrTexture: true,
          mrCustomTexture: true,
          mbCustomTexture: true,
          brCustomTexture: true,
          deleteControlTexture: false,
          cloneTexture: false,
          lockTexture: false,
          // unlockTexture: true,
        });
      }
    } else {
      if (textureFabric) {
        newSelection.setControlsVisibility({
          mrCustom: false,
          mbCustom: false,
          brCustom: false,
          mtr: false,
          unlock: false,
          lock: false,
          clone: false,
          deleteControl: false,

          mtrTexture: true,
          mrCustomTexture: true,
          mbCustomTexture: true,
          brCustomTexture: true,
          deleteControlTexture: true,
          cloneTexture: true,
          lockTexture: true,
          // unlockTexture: true,
        });
      }
    }

    fabricCanvas.setActiveObject(newSelection);
  } else if (selectionObjectsFabricList.length === 1) {
    fabricCanvas.setActiveObject(selectionObjectsFabricList[0]);

    const islocked = selectionObjectsFabricList[0].islocked;

    if (
      getState().workspacesMode.mode === enumWorkspaceModes.EDIT_PRINT_AREAS
    ) {
      if (textureFabric) {
        selectionObjectsFabricList[0].setControlsVisibility({
          mtrTexture: true,
          mrCustomTexture: true,
          mbCustomTexture: true,
          brCustomTexture: true,
          deleteControlTexture: false,
          cloneTexture: false,
          lockTexture: false,
          unlockTexture: false,
        });
      } else {
        selectionObjectsFabricList[0].setControlsVisibility({
          mrCustom: true,
          mbCustom: true,
          brCustom: true,
          mtr: true,
          unlock: false,
          lock: false,
          clone: false,
          deleteControl: false,
        });
      }
    } else {
      if (textureFabric) {
        selectionObjectsFabricList[0].setControlsVisibility({
          mtrTexture: !islocked,
          mrCustomTexture: !islocked,
          mbCustomTexture: !islocked,
          brCustomTexture: !islocked,
          deleteControlTexture: !islocked,
          cloneTexture: !islocked,
          lockTexture: !islocked,
          unlockTexture: islocked,
        });
      } else {
        selectionObjectsFabricList[0].setControlsVisibility({
          mrCustom: !islocked,
          mbCustom: !islocked,
          brCustom: !islocked,
          mtr: !islocked,
          unlock: islocked,
          lock: !islocked,
          clone: !islocked,
          deleteControl: !islocked,
        });
      }
    }
  }
};

export const removeElementFromFabric = (fabric, elementId) => {
  fabric.getObjects().forEach(function (object) {
    if (object.id !== elementId) return; // find specyfic object
    fabric.remove(object);
  });
};

export const findRelatedTextureFabric = (
  refTextureFabricArray,
  fabricSelectedPrintArea
) => {
  return refTextureFabricArray.find(
    (item) => item.id === fabricSelectedPrintArea
  );
};

export const differenceCloneElementfromFabric = async (
  fabricCanv,
  elementId,
  setId,
  offset = 0,
  resolution = 1,
  textureFabric = false
) => {
  const cornerSize = 30;
  let clonedObject;

  const clonePromises = fabricCanv.getObjects().map((object) => {
    return new Promise((resolve, reject) => {
      if (object.id !== elementId) {
        resolve(null); // Skip non-matching objects immediately
        return;
      }

      if (object.clipPath) {
        object.clipPath = undefined;
      }

      object.clone((cloned) => {
        if (!cloned) {
          reject(new Error('Failed to clone object'));
          return;
        }

        const islockedProps = {
          islocked: object.islocked,
          lockMovementX: object.islocked,
          lockMovementY: object.islocked,
          lockRotation: object.islocked,
          lockScalingX: object.islocked,
          lockScalingY: object.islocked,
          lockUniScaling: object.islocked,
        };
        if (object.islocked) {
          cloned.hoverCursor = 'auto';
        } else {
          cloned.hoverCursor = 'move';
        }

        // let addLeftGroup = object.group.left;
        // let addTopGroup = object.group.top;

        let properties = {
          left: (object.left + offset) * resolution,
          top: (object.top + offset) * resolution,
          type: object.type,
          id: setId,
          flipX: object.flipX,
          flipY: object.flipY,
          angle: object.angle,

          visible: object.visible,
          cornerSize: cornerSize,

          ...islockedProps,
        };

        if (cloned.type === enumLayerType.TEXT) {
          properties.fontSize = object.fontSize * resolution;
          properties.strokeWidth = object.strokeWidth * resolution;
          properties.shadowSettings = _.cloneDeep(object.shadowSettings);

          if (object.shadow) {
            cloned.shadow.offsetX = cloned.shadow.offsetX * resolution;
            cloned.shadow.blur = cloned.shadow.blur * resolution;
            cloned.shadow.offsetY = cloned.shadow.offsetY * resolution;
          }
        } else if (cloned.type === enumLayerType.IMAGE) {
          properties.scaleX = object.scaleX * resolution; // Fixed property name case
          properties.scaleY = object.scaleY * resolution;

          properties.activeFilters = object.activeFilters;
          properties.filterSettings = _.cloneDeep(object.filterSettings);

          properties.srcdst = object.srcdst;
          properties.srckey = object.srckey;

          cloned.filters = [];
          if (object.filters.length > 0 && object.activeFilters) {
            for (const filterName in object.filterSettings) {
              const filterIndex = filtersList.findIndex(
                (item) => item.filterName === filterName
              );

              if (filterIndex) {
                if (filtersList[filterIndex].type === 'BOOLEAN') {
                  if (object.filterSettings[filterName].value) {
                    cloned.filters[filterIndex] = addFilter(
                      filterIndex,
                      object.filterSettings[filterName].parameter,
                      object.filterSettings[filterName].value
                    );
                  }
                } else {
                  cloned.filters[filterIndex] = addFilter(
                    filterIndex,
                    object.filterSettings[filterName].parameter,
                    object.filterSettings[filterName].value
                  );
                }
              }
            }
            cloned.applyFilters();
          }

          // properties.filters = object.filters;
        } else {
          properties.height = (cloned.height + offset) * resolution;
          properties.width = (cloned.width + offset) * resolution;
        }

        cloned.set(properties);

        if (cloned) {
          // textureFabic.fabricRef.current.add(cloned2);

          setTimeout(() => {
            fabricCanv.add(cloned);
            resolve(cloned);
          }, 0);
        }
      });

      if (fabricCanv.clippingShape) {
        object.clipPath = fabricCanv.clippingShape;
      }
    });
  });

  const clonedObjects = await Promise.all(clonePromises);
  clonedObject = clonedObjects.find((obj) => obj !== null); // Find the first successfully cloned object

  return clonedObject;
};

export const cloneElementfromFabric = async (
  fabric,
  elementId,
  setId,
  offset = 0,
  resolution = 1,
  textureFabric = false
) => {
  const cornerSize = 30;
  let clonedObject;

  const clonePromises = fabric.getObjects().map((object) => {
    return new Promise((resolve, reject) => {
      if (object.id !== elementId) {
        resolve(null); // Skip non-matching objects immediately
        return;
      }

      if (object.clipPath) {
        object.clipPath = undefined;
      }

      object.clone((cloned) => {
        if (!cloned) {
          reject(new Error('Failed to clone object'));
          return;
        }

        const islockedProps = {
          islocked: object.islocked,
          lockMovementX: object.islocked,
          lockMovementY: object.islocked,
          lockRotation: object.islocked,
          lockScalingX: object.islocked,
          lockScalingY: object.islocked,
          lockUniScaling: object.islocked,
        };
        if (object.islocked) {
          cloned.hoverCursor = 'auto';
        } else {
          cloned.hoverCursor = 'move';
        }

        let properties = {
          left: (object.left + offset) * resolution,
          top: (object.top + offset) * resolution,
          type: object.type,
          id: setId,
          flipX: object.flipX,
          flipY: object.flipY,
          angle: object.angle,

          visible: object.visible,
          cornerSize: cornerSize,

          ...islockedProps,
        };

        if (cloned.type === enumLayerType.TEXT) {
          properties.fontSize = object.fontSize * resolution;
          properties.strokeWidth = object.strokeWidth * resolution;

          properties.shadowSettings = _.cloneDeep(object.shadowSettings);

          if (object.shadow) {
            cloned.shadow.offsetX = cloned.shadow.offsetX * resolution;
            cloned.shadow.blur = cloned.shadow.blur * resolution;
            cloned.shadow.offsetY = cloned.shadow.offsetY * resolution;
          }
        } else if (cloned.type === enumLayerType.IMAGE) {
          properties.scaleX = object.scaleX * resolution; // Fixed property name case
          properties.scaleY = object.scaleY * resolution;

          properties.activeFilters = object.activeFilters;
          properties.filterSettings = { ...object.filterSettings };

          properties.srcdst = object.srcdst;
          properties.srckey = object.srckey;

          cloned.filters = [];
          if (object.filters.length > 0 && object.activeFilters) {
            for (const filterName in object.filterSettings) {
              const filterIndex = filtersList.findIndex(
                (item) => item.filterName === filterName
              );

              if (filterIndex) {
                if (filtersList[filterIndex].type === 'BOOLEAN') {
                  if (object.filterSettings[filterName].value) {
                    cloned.filters[filterIndex] = addFilter(
                      filterIndex,
                      object.filterSettings[filterName].parameter,
                      object.filterSettings[filterName].value
                    );
                  }
                } else {
                  cloned.filters[filterIndex] = addFilter(
                    filterIndex,
                    object.filterSettings[filterName].parameter,
                    object.filterSettings[filterName].value
                  );
                }
              }
            }
            cloned.applyFilters();
          }

          // properties.filters = object.filters;
        } else {
          properties.height = (cloned.height + offset) * resolution;
          properties.width = (cloned.width + offset) * resolution;
        }

        cloned.set(properties);

        // if (cloned) {
        //   // textureFabic.fabricRef.current.add(cloned2);
        //   fabric.add(cloned);

        //   setTimeout(() => {
        //     resolve(cloned);
        //   }, 0);
        // }

        setTimeout(() => {
          resolve(cloned);
        }, 0);
      });

      if (fabric.clippingShape) {
        object.clipPath = fabric.clippingShape;
      }
    });
  });

  const clonedObjects = await Promise.all(clonePromises);
  clonedObject = clonedObjects.find((obj) => obj !== null); // Find the first successfully cloned object

  return clonedObject;
};

export const cloneElementfromClipboard = async (
  fabric,
  elementId,
  setId,
  offset = 0,
  resolution = 1
) => {
  const cornerSize = 30;
  let clonedObject;

  const clonePromises = fabric.map((object) => {
    return new Promise((resolve, reject) => {
      if (object.id !== elementId) {
        resolve(null); // Skip non-matching objects immediately
        return;
      }

      if (object.clipPath) {
        object.clipPath = undefined;
      }

      object.clone((cloned) => {
        if (!cloned) {
          reject(new Error('Failed to clone object'));
          return;
        }

        const islockedProps = {
          islocked: object.islocked,
          lockMovementX: object.islocked,
          lockMovementY: object.islocked,
          lockRotation: object.islocked,
          lockScalingX: object.islocked,
          lockScalingY: object.islocked,
          lockUniScaling: object.islocked,
        };
        if (object.islocked) {
          cloned.hoverCursor = 'auto';
        } else {
          cloned.hoverCursor = 'move';
        }

        let properties = {
          left: (cloned.left + offset) * resolution,
          top: (cloned.top + offset) * resolution,
          type: cloned.type,
          id: setId,
          visible: cloned.visible,
          cornerSize: cornerSize,
          ...islockedProps,
        };

        if (cloned.type === enumLayerType.TEXT) {
          properties.fontSize = cloned.fontSize * resolution;
          properties.strokeWidth = cloned.strokeWidth * resolution;

          properties.shadowSettings = _.cloneDeep(object.shadowSettings);

          if (cloned.shadow) {
            cloned.shadow.offsetX = cloned.shadow.offsetX * resolution;
            cloned.shadow.blur = cloned.shadow.blur * resolution;
            cloned.shadow.offsetY = cloned.shadow.offsetY * resolution;
          }
        } else if (cloned.type === enumLayerType.IMAGE) {
          properties.scaleX = cloned.scaleX * resolution; // Fixed property name case
          properties.scaleY = cloned.scaleY * resolution;
          properties.activeFilters = object.activeFilters;
          properties.filterSettings = { ...object.filterSettings };

          properties.srcdst = object.srcdst;
          properties.srckey = object.srckey;

          cloned.filters = [];
          if (object.filters.length > 0 && object.activeFilters) {
            for (const filterName in object.filterSettings) {
              const filterIndex = filtersList.findIndex(
                (item) => item.filterName === filterName
              );

              if (filterIndex) {
                if (filtersList[filterIndex].type === 'BOOLEAN') {
                  if (object.filterSettings[filterName].value) {
                    cloned.filters[filterIndex] = addFilter(
                      filterIndex,
                      object.filterSettings[filterName].parameter,
                      object.filterSettings[filterName].value
                    );
                  }
                } else {
                  cloned.filters[filterIndex] = addFilter(
                    filterIndex,
                    object.filterSettings[filterName].parameter,
                    object.filterSettings[filterName].value
                  );
                }
              }
            }
            cloned.applyFilters();
          }

          // properties.filters = object.filters;
        } else {
          properties.height = (cloned.height + offset) * resolution;
          properties.width = (cloned.width + offset) * resolution;
        }

        cloned.set(properties);

        resolve(cloned);
      });
      // if (fabric.clippingShape) {
      //   object.clipPath = fabric.clippingShape;
      // }
    });
  });

  const clonedObjects = await Promise.all(clonePromises);
  clonedObject = clonedObjects.find((obj) => obj !== null); // Find the first successfully cloned object

  return clonedObject;
};

export const createFabricGroup = (
  fabricCanvas,
  elements,
  position,
  offset = 0,
  resolution = 1,
  angle = 0,
  scaleX = 1,
  scaleY = 1
) => {
  const newGroup = new fabric.ActiveSelection(elements, {
    canvas: fabricCanvas,
    left: (position.left + offset) * resolution,
    top: (position.top + offset) * resolution,
    angle: angle,
    scaleX: scaleX,
    scaleY: scaleY,
  });
  return newGroup;
};

export const resolutionProps = (qualityId, fabricSettings) => {
  const quality = qualityOptions.find((item) => item.value === qualityId);
  const { textureSize } = quality;

  const { width: workspaceWidth, height: workspaceHeight } =
    fabricSettings.workspaceSize;

  return { textureSize, workspaceWidth, workspaceHeight };
};

export const lockElementInFabric = (
  fabric,
  elementId,
  islocked,
  textureFabric = false
) => {
  fabric.getObjects().forEach(function (object) {
    if (object.id !== elementId) return; // find specyfic object

    object.islocked = islocked;

    object.set({
      lockMovementX: islocked,
      lockMovementY: islocked,
      lockRotation: islocked,
      lockScalingX: islocked,
      lockScalingY: islocked,
      lockUniScaling: islocked,
    });

    if (islocked) {
      object.hoverCursor = 'auto';
    } else {
      object.hoverCursor = 'move';
    }

    if (textureFabric) {
      object.setControlsVisibility({
        mtrTexture: !islocked,
        mrCustomTexture: !islocked,
        mbCustomTexture: !islocked,
        brCustomTexture: !islocked,
        deleteControlTexture: !islocked,
        cloneTexture: !islocked,
        lockTexture: !islocked,
        unlockTexture: islocked,
      });
    } else {
      object.setControlsVisibility({
        mrCustom: !islocked,
        mbCustom: !islocked,
        brCustom: !islocked,
        mtr: !islocked,
        unlock: islocked,
        lock: !islocked,
        clone: !islocked,
        deleteControl: !islocked,
      });
    }
  });
};

export const visabilityElementInFabric = (fabric, elementId, visible) => {
  fabric.getObjects().forEach(function (object) {
    if (object.id !== elementId) return; // find specyfic object

    object.set({
      visible: visible,
    });
  });
};

export const lockSelectedGroupInFabric = (
  fabric,
  islocked,
  textureFabric = false
) => {
  const object = fabric.getActiveObject();

  object.islocked = islocked;

  object.set({
    lockMovementX: islocked,
    lockMovementY: islocked,
    lockRotation: islocked,
    lockScalingX: islocked,
    lockScalingY: islocked,
    lockUniScaling: islocked,
  });

  if (islocked) {
    object.hoverCursor = 'auto';
  } else {
    object.hoverCursor = 'move';
  }

  if (textureFabric) {
    object.setControlsVisibility({
      mtrTexture: !islocked,
      mrCustomTexture: !islocked,
      mbCustomTexture: !islocked,
      brCustomTexture: !islocked,
      deleteControlTexture: !islocked,
      cloneTexture: !islocked,
      lockTexture: !islocked,
      unlockTexture: islocked,
    });
  } else {
    object.setControlsVisibility({
      mrCustom: !islocked,
      mbCustom: !islocked,
      brCustom: !islocked,
      mtr: !islocked,
      unlock: islocked,
      lock: !islocked,
      clone: !islocked,
      deleteControl: !islocked,
    });
  }
};

function findNewTitleNumber(helperLayers, pattern) {
  // Regular expression to match 'Image ' followed by a number
  // const imagePattern = /^Image (\d+)$/;

  // Extract numbers from matching elements and sort them
  const numbers = helperLayers
    .map((text) => {
      const match = text.match(pattern);
      return match ? parseInt(match[1], 10) : null;
    })
    .filter((number) => number !== null)
    .sort((a, b) => a - b);

  // Find the smallest missing number in the sorted list starting from 1
  let missingNumber = 1;
  for (let number of numbers) {
    if (number === missingNumber) {
      missingNumber++;
    } else if (number > missingNumber) {
      break;
    }
  }

  // Add new element with the identified missing number
  return missingNumber;
}

const getAllTitleList = (layers) => {
  const titleList = [];
  for (let key in layers) {
    layers[key].forEach((item) => {
      titleList.push(item.title);
    });
  }
  return titleList;
};

export const addNewObject = (set, coordination, printAreaId) => {
  const cornerSize = 30;

  set((state) => {
    // collect resolutions
    const { textureSize, workspaceWidth, workspaceHeight } = resolutionProps(
      state.qualityId,
      state.fabricSettings
    );
    //------

    const helperLayers = { ...state.layers };
    const helperInitialLayers = [...state.layersInitial];
    const elementId = uuidv4();

    let returnSelectedLayers = [elementId];

    if (state.workspacesMode.type === enumLayerType.TEXT) {
      const findModel = getState().design.productDesignLoadedData.find(
        (item) => item.idVariant === getState().selectedProductOptionsVariantId
      );

      const calibrationValue = findModel.printAreas.find(
        (item) => item.id === printAreaId
      );
      const { maxWidth, maxHeight } = calcMaxElementSize(calibrationValue);

      // ----- Add Layer Object

      const textValue =
        'Text ' +
        findNewTitleNumber(getAllTitleList(helperLayers), /^Text (\d+)$/);

      const addLayer = {
        id: elementId,
        title: textValue,
        groupId: printAreaId,
        type: enumLayerType.TEXT,
        props: {
          text: textValue,
        },
        islocked: false,
        visible: true,
      };

      helperInitialLayers.unshift(addLayer);
      helperLayers[printAreaId].unshift(addLayer);

      // ----- Add Workspace Object

      const font = fontList.find((item) => item.id === 20);

      let fontObsever = new FontFaceObserver(font.name);
      const allCaracters = 'ąĄęĘćĆźŹżŻśŚóÓłŁ';

      fontObsever.load(allCaracters).then(() => {
        if (state.fabricSelectedPrintArea === printAreaId) {
          if (state.refFabric.current) {
            const text = new fabric.Text(textValue, {
              left: coordination.x,
              top: coordination.y,
              fontSize: 40,
              fill: 'rgba(50,50,50,1)',
              angle: 0,
              flipX: false,
              flipY: false,
              centeredScaling: true,
              originX: 'center',
              originY: 'center',
              cornerSize: cornerSize,
              id: elementId,
              fontWeight: 'normal',
              fontStyle: 'normal',
              textAlign: 'left',
              linethrough: false,
              underline: false,
              objectCaching: false,
              stroke: null,
              strokeWidth: 1,
              paintFirst: 'stroke',
              fontFamily: font.name,
              type: enumLayerType.TEXT,
              islocked: false,
              visible: true,
              // noScaleCache: true,
              // padding: 0,
              shadowSettings: {
                color: 'rgba(255,255,255,1)',
                blur: 3,
                offsetX: 0,
                offsetY: 0,
              },
            });

            // Ustawianie skali obrazu
            const scaleX = maxWidth / text.width;
            const scaleY = maxHeight / text.height;
            const scale = Math.min(scaleX, scaleY);
            text.scale(scale);

            text.set('lineHeight', 1);
            text.set('charSpacing', 0);

            // text.shouldCache = () => false;

            if (state.refFabric.current.clippingShape) {
              text.clipPath = state.refFabric.current.clippingShape;
            }

            state.refFabric.current.add(text);
            // refFabric.current.discardActiveObject();
            state.refFabric.current.setActiveObject(text);
            sortLayers(state.refFabric.current, helperLayers[printAreaId]);
          }
        }

        // ----- Add Texture fabric Object

        for (const textureFabric of state.refTextureFabricArray) {
          if (textureFabric.id === printAreaId) {
            if (textureFabric.fabricRef.current) {
              const text = new fabric.Text(textValue, {
                left: coordination.x * (textureSize / workspaceWidth),
                top: coordination.y * (textureSize / workspaceHeight),
                fontSize: 40 * (textureSize / workspaceHeight),
                fill: 'rgba(50,50,50,1)',
                angle: 0,
                flipX: false,
                flipY: false,
                centeredScaling: true,
                originX: 'center',
                originY: 'center',
                cornerSize: cornerSize,
                id: elementId,
                fontWeight: 'normal',
                fontStyle: 'normal',
                textAlign: 'left',
                linethrough: false,
                underline: false,
                // objectCaching: false,
                stroke: null,
                strokeWidth: 1 * (textureSize / workspaceHeight),
                paintFirst: 'stroke',
                fontFamily: font.name,
                type: enumLayerType.TEXT,
                islocked: false,
                visible: true,
                shadowSettings: {
                  color: 'rgba(255,255,255,1)',
                  blur: 3,
                  offsetX: 0,
                  offsetY: 0,
                },
              });

              // Ustawianie skali obrazu
              const scaleX = maxWidth / text.width;
              const scaleY = maxHeight / text.height;
              const scale = Math.min(scaleX, scaleY);
              text.scale(scale * (textureSize / workspaceWidth));

              text.set('lineHeight', 1);
              text.set('charSpacing', 0);

              text.setControlsVisibility({
                mrCustom: false,
                mbCustom: false,
                brCustom: false,
                mtr: false,
                unlock: false,
                lock: false,
                clone: false,
                deleteControl: false,
                mtrTexture: true,
                mrCustomTexture: true,
                mbCustomTexture: true,
                brCustomTexture: true,
                deleteControlTexture: true,
                cloneTexture: true,
                lockTexture: true,
                // unlockTexture: true,
              });

              if (textureFabric.fabricRef.current.clippingShape) {
                text.clipPath = textureFabric.fabricRef.current.clippingShape;
              }

              textureFabric.fabricRef.current.add(text);
              textureFabric.fabricRef.current.setActiveObject(text);
              sortLayers(
                textureFabric.fabricRef.current,
                helperLayers[textureFabric.id]
              );

              setState((state) => {
                return {
                  forceRefreshingToolTipTools:
                    state.forceRefreshingToolTipTools + 1,
                };
              });
            }
            break;
          }
        }
      });
    } else if (state.workspacesMode.type === enumLayerType.IMAGE) {
      const findModel = getState().design.productDesignLoadedData.find(
        (item) => item.idVariant === getState().selectedProductOptionsVariantId
      );

      const calibrationValue = findModel.printAreas.find(
        (item) => item.id === printAreaId
      );
      const { maxWidth, maxHeight } = calcMaxElementSize(calibrationValue);

      // ----- Add Layer Object

      const addLayer = {
        id: elementId,
        title:
          'Image ' +
          findNewTitleNumber(getAllTitleList(helperLayers), /^Image (\d+)$/),
        groupId: printAreaId,
        type: enumLayerType.IMAGE,
        islocked: false,
        visible: true,
        props: {
          url: '/assets/image/image_place_holder.svg',
          placeholder: true,
          srcdst: enumSrcDst.EMPTY,
          srckey: null,
        },
      };

      helperInitialLayers.unshift(addLayer);
      helperLayers[printAreaId].unshift(addLayer);

      // ----- Add Workspace Object
      if (state.fabricSelectedPrintArea === printAreaId) {
        if (state.refFabric.current) {
          fabric.Image.fromURL(
            '/assets/image/image_place_holder.svg',
            function (image) {
              const scaleX = maxWidth / image.width;
              const scaleY = maxHeight / image.height;
              const scale = Math.min(scaleX, scaleY);

              const imgObject = image.set({
                left: coordination.x,
                top: coordination.y,
                width: image.width,
                height: image.height,
                angle: 0,
                flipX: false,
                flipY: false,
                centeredScaling: true,
                originX: 'center',
                originY: 'center',
                cornerSize: cornerSize,
                id: elementId,
                type: enumLayerType.IMAGE,
                islocked: false,
                visible: true,
                activeFilters: false,
                filterSettings: {},
                srcdst: enumSrcDst.EMPTY,
                srckey: null,
              });
              // Ustawianie skali obrazu
              imgObject.scale(scale);

              if (state.refFabric.current.clippingShape) {
                imgObject.clipPath = state.refFabric.current.clippingShape;
              }

              state.refFabric.current.add(imgObject);
              state.refFabric.current.setActiveObject(imgObject);

              sortLayers(state.refFabric.current, helperLayers[printAreaId]);
            },
            { crossOrigin: 'Anonymous' }
          );
        }
      }

      // ----- Add Texture fabric Object
      for (const textureFabric of state.refTextureFabricArray) {
        if (textureFabric.id === printAreaId) {
          if (textureFabric.fabricRef.current) {
            fabric.Image.fromURL(
              '/assets/image/image_place_holder.svg',
              function (image) {
                const scaleX = maxWidth / image.width;
                const scaleY = maxHeight / image.height;
                const scale = Math.min(scaleX, scaleY);

                const imgObject = image.set({
                  left: coordination.x * (textureSize / workspaceWidth),
                  top: coordination.y * (textureSize / workspaceHeight),
                  width: image.width,
                  height: image.height,
                  scaleX: textureSize / workspaceWidth,
                  scaleY: textureSize / workspaceWidth,
                  angle: 0,
                  flipX: false,
                  flipY: false,
                  centeredScaling: true,
                  originX: 'center',
                  originY: 'center',
                  cornerSize: cornerSize,
                  id: elementId,
                  type: enumLayerType.IMAGE,
                  islocked: false,
                  visible: true,
                  activeFilters: false,
                  filterSettings: {},
                  srcdst: enumSrcDst.EMPTY,
                  srckey: null,
                });

                imgObject.scale(scale * (textureSize / workspaceWidth));

                imgObject.setControlsVisibility({
                  mrCustom: false,
                  mbCustom: false,
                  brCustom: false,
                  mtr: false,
                  unlock: false,
                  lock: false,
                  clone: false,
                  deleteControl: false,
                  mtrTexture: true,
                  mrCustomTexture: true,
                  mbCustomTexture: true,
                  brCustomTexture: true,
                  deleteControlTexture: true,
                  cloneTexture: true,
                  lockTexture: true,
                  // unlockTexture: true,
                });

                if (textureFabric.fabricRef.current.clippingShape) {
                  imgObject.clipPath =
                    textureFabric.fabricRef.current.clippingShape;
                }

                textureFabric.fabricRef.current.add(imgObject);
                textureFabric.fabricRef.current.setActiveObject(imgObject);

                sortLayers(
                  textureFabric.fabricRef.current,
                  helperLayers[textureFabric.id]
                );

                setState((state) => {
                  return {
                    forceRefreshingToolTipTools:
                      state.forceRefreshingToolTipTools + 1,
                  };
                });
              },
              { crossOrigin: 'Anonymous' }
            );
          }
          break;
        }
      }
    } else if (state.workspacesMode.type === enumLayerType.S_RECT) {
      const findModel = getState().design.productDesignLoadedData.find(
        (item) => item.idVariant === getState().selectedProductOptionsVariantId
      );

      const calibrationValue = findModel.printAreas.find(
        (item) => item.id === printAreaId
      );
      const { maxWidth, maxHeight } = calcMaxElementSize(calibrationValue);

      // ----- Add Layer Object

      const textValue =
        'Rect ' +
        findNewTitleNumber(getAllTitleList(helperLayers), /^Rect (\d+)$/);

      const addLayer = {
        id: elementId,
        title: textValue,
        groupId: printAreaId,
        type: enumLayerType.S_RECT,
        props: {
          text: textValue,
        },
        islocked: false,
        visible: true,
      };

      helperInitialLayers.unshift(addLayer);
      helperLayers[printAreaId].unshift(addLayer);

      if (state.fabricSelectedPrintArea === printAreaId) {
        if (state.refFabric.current) {
          const text = new fabric.Rect({
            left: coordination.x,
            top: coordination.y,
            height: 100,
            width: 100,
            fill: 'rgba(50,50,50,1)',
            angle: 0,
            flipX: false,
            flipY: false,
            centeredScaling: true,
            originX: 'center',
            originY: 'center',
            cornerSize: cornerSize,
            id: elementId,

            type: enumLayerType.S_RECT,
            islocked: false,
            visible: true,

            stroke: null,
            strokeWidth: 50,
            paintFirst: 'stroke',
            strokeUniform: true,

            shadowSettings: {
              color: 'rgba(255,255,255,1)',
              blur: 3,
              offsetX: 0,
              offsetY: 0,
            },
          });

          // Ustawianie skali obrazu
          const scaleX = maxWidth / text.width;
          const scaleY = maxHeight / text.height;
          const scale = Math.min(scaleX, scaleY);
          text.scale(scale);

          // text.shouldCache = () => false;

          if (state.refFabric.current.clippingShape) {
            text.clipPath = state.refFabric.current.clippingShape;
          }

          state.refFabric.current.add(text);
          // refFabric.current.discardActiveObject();
          state.refFabric.current.setActiveObject(text);
          sortLayers(state.refFabric.current, helperLayers[printAreaId]);
        }
      }

      // ----- Add Texture fabric Object

      for (const textureFabric of state.refTextureFabricArray) {
        if (textureFabric.id === printAreaId) {
          if (textureFabric.fabricRef.current) {
            const text = new fabric.Rect({
              left: coordination.x * (textureSize / workspaceWidth),
              top: coordination.y * (textureSize / workspaceHeight),
              height: 100 * (textureSize / workspaceWidth),
              width: 100 * (textureSize / workspaceWidth),
              fill: 'rgba(50,50,50,1)',
              angle: 0,
              flipX: false,
              flipY: false,
              centeredScaling: true,
              originX: 'center',
              originY: 'center',
              cornerSize: cornerSize,
              id: elementId,

              stroke: null,
              strokeWidth: 50 * (textureSize / workspaceHeight),
              strokeUniform: true,
              paintFirst: 'stroke',

              type: enumLayerType.S_RECT,
              islocked: false,
              visible: true,
              shadowSettings: {
                color: 'rgba(255,255,255,1)',
                blur: 3,
                offsetX: 0,
                offsetY: 0,
              },
            });

            // Ustawianie skali obrazu
            const scaleX = maxWidth / text.width;
            const scaleY = maxHeight / text.height;
            const scale = Math.min(scaleX, scaleY);
            text.scale(scale * (textureSize / workspaceWidth));

            text.setControlsVisibility({
              mrCustom: false,
              mbCustom: false,
              brCustom: false,
              mtr: false,
              unlock: false,
              lock: false,
              clone: false,
              deleteControl: false,
              mtrTexture: true,
              mrCustomTexture: true,
              mbCustomTexture: true,
              brCustomTexture: true,
              deleteControlTexture: true,
              cloneTexture: true,
              lockTexture: true,
              // unlockTexture: true,
            });

            if (textureFabric.fabricRef.current.clippingShape) {
              text.clipPath = textureFabric.fabricRef.current.clippingShape;
            }

            textureFabric.fabricRef.current.add(text);
            textureFabric.fabricRef.current.setActiveObject(text);
            sortLayers(
              textureFabric.fabricRef.current,
              helperLayers[textureFabric.id]
            );

            setState((state) => {
              return {
                forceRefreshingToolTipTools:
                  state.forceRefreshingToolTipTools + 1,
              };
            });
          }
          break;
        }
      }
    } else if (
      state.workspacesMode.type === enumLayerType.PASTE_FROM_CLIPBOARD
    ) {
      const destinyTextureFabric = state.refTextureFabricArray.find(
        (item) => item.id === printAreaId
      );

      if (state.clipboard.length > 1) {
        try {
          set(async (state) => {});
        } catch (error) {
          console.error('Error cloning object:', error);
        }
      } else {
        try {
          set(async (state) => {
            const clonedObjectsTextureFabric = [];
            const clonedObjectsWorkspace = [];
            const clonedLayers = [];
            const elementIdList = [];
            const selectedLayers = [];

            for (const copyFabric of state.clipboard) {
              if (copyFabric.elements.length > 1) {
                // ------ Clone element in layers

                copyFabric.layers.forEach(function (obj) {
                  const elementId = uuidv4();
                  elementIdList.push({ newId: elementId, relatedId: obj.id });
                  selectedLayers.push(elementId);

                  const layerItem = obj;

                  const addLayer = addNewLayer(
                    elementId,
                    printAreaId,
                    layerItem.type,
                    layerItem.islocked,
                    layerItem.title,
                    layerItem.props,
                    layerItem.visible
                  );

                  helperLayers[printAreaId].unshift(addLayer);
                  helperInitialLayers.unshift(addLayer);
                });

                returnSelectedLayers = selectedLayers;

                // ------ Clone element in Texture fabric

                const clonePromises = copyFabric.elements.map(
                  async (obj, index) => {
                    const findRelatedId = elementIdList.find(
                      (item) => item.relatedId === obj.id
                    );

                    const cloned1 = await cloneElementfromClipboard(
                      copyFabric.elements,
                      obj.id,
                      findRelatedId.newId,
                      0
                      // textureSize / workspaceWidth
                    );

                    if (cloned1) {
                      if (
                        destinyTextureFabric.fabricRef.current.clippingShape
                      ) {
                        cloned1.clipPath =
                          destinyTextureFabric.fabricRef.current.clippingShape;
                      }

                      destinyTextureFabric.fabricRef.current.add(cloned1);
                      clonedObjectsTextureFabric.push(cloned1);
                    }
                  }
                );

                // Wait for all cloning promises to resolve
                Promise.all(clonePromises).then(() => {
                  destinyTextureFabric.fabricRef.current.discardActiveObject();
                  // Create group with cloned elements from Texture fabric

                  const newGroup = new fabric.ActiveSelection(
                    clonedObjectsTextureFabric,
                    {
                      canvas: destinyTextureFabric.fabricRef.current,
                      left: coordination.x * (textureSize / workspaceWidth),
                      top: coordination.y * (textureSize / workspaceHeight),
                      angle: copyFabric.groupProps.angle,
                      scaleX:
                        copyFabric.groupProps.scaleX *
                        (textureSize / workspaceHeight),
                      scaleY:
                        copyFabric.groupProps.scaleY *
                        (textureSize / workspaceHeight),
                    }
                  );

                  newGroup.setControlsVisibility({
                    mrCustom: false,
                    mbCustom: false,
                    brCustom: false,
                    mtr: false,
                    unlock: false,
                    lock: false,
                    clone: false,
                    deleteControl: false,

                    mtrTexture: true,
                    mrCustomTexture: true,
                    mbCustomTexture: true,
                    brCustomTexture: true,
                    deleteControlTexture: true,
                    cloneTexture: true,
                    lockTexture: true,
                    // unlockTexture: true,
                  });

                  destinyTextureFabric.fabricRef.current.setActiveObject(
                    newGroup
                  );
                  destinyTextureFabric.fabricRef.current.requestRenderAll();

                  // sort fabric layers
                  sortLayers(
                    destinyTextureFabric.fabricRef.current,
                    helperLayers[printAreaId]
                  );
                });

                // ------ Clone element in workspace

                if (state.refFabric.current) {
                  if (printAreaId === state.fabricSelectedPrintArea) {
                    const clonePromises2 = copyFabric.elements.map(
                      async (obj, index) => {
                        const findRelatedId = elementIdList.find(
                          (item) => item.relatedId === obj.id
                        );

                        const cloned2 = await cloneElementfromClipboard(
                          copyFabric.elements,
                          obj.id,
                          findRelatedId.newId,
                          0
                          // 1
                        );

                        if (cloned2) {
                          if (state.refFabric.current.clippingShape) {
                            cloned2.clipPath =
                              state.refFabric.current.clippingShape;
                          }

                          state.refFabric.current.add(cloned2);
                          clonedObjectsWorkspace.push(cloned2);
                        }
                      }
                    );

                    // Wait for all cloning promises to resolve
                    Promise.all(clonePromises2)
                      .then(() => {
                        state.refFabric.current.discardActiveObject();
                        // Create group with cloned elements from workspace

                        const newGroupWorkspace = new fabric.ActiveSelection(
                          clonedObjectsWorkspace,
                          {
                            canvas: state.refFabric.current,
                            left: coordination.x,
                            top: coordination.y,
                            angle: copyFabric.groupProps.angle,
                            scaleX: copyFabric.groupProps.scaleX,
                            scaleY: copyFabric.groupProps.scaleY,
                          }
                        );

                        state.refFabric.current.setActiveObject(
                          newGroupWorkspace
                        );
                        state.refFabric.current.requestRenderAll();

                        // sort fabric layers
                        sortLayers(
                          state.refFabric.current,
                          helperLayers[state.fabricSelectedPrintArea]
                        );
                      })
                      .catch((error) => {
                        // Handle any errors that occurred during cloning
                        console.error(
                          'An error occurred while cloning elements:',
                          error
                        );
                      });
                  }
                }
              } else {
                const obj = copyFabric.elements[0];

                // ------ Clone element in layers
                const layerItem = copyFabric.layers[0];

                const addLayer = addNewLayer(
                  elementId,
                  printAreaId,
                  layerItem.type,
                  layerItem.islocked,
                  layerItem.title,
                  layerItem.props,
                  layerItem.visible
                );

                helperLayers[printAreaId].unshift(addLayer);
                helperInitialLayers.unshift(addLayer);

                // ------ Clone element in Texture fabric
                const cloned1 = await cloneElementfromClipboard(
                  copyFabric.elements,
                  obj.id,
                  elementId,
                  0,
                  textureSize / workspaceWidth
                );

                if (cloned1) {
                  if (destinyTextureFabric.fabricRef.current.clippingShape) {
                    cloned1.clipPath =
                      destinyTextureFabric.fabricRef.current.clippingShape;
                  }

                  cloned1.setControlsVisibility({
                    mrCustom: false,
                    mbCustom: false,
                    brCustom: false,
                    mtr: false,
                    unlock: false,
                    lock: false,
                    clone: false,
                    deleteControl: false,
                    mtrTexture: true,
                    mrCustomTexture: true,
                    mbCustomTexture: true,
                    brCustomTexture: true,
                    deleteControlTexture: true,
                    cloneTexture: true,
                    lockTexture: true,
                    // unlockTexture: true,
                  });

                  cloned1.set({
                    left: coordination.x * (textureSize / workspaceWidth),
                    top: coordination.y * (textureSize / workspaceHeight),
                  });

                  destinyTextureFabric.fabricRef.current.discardActiveObject();
                  destinyTextureFabric.fabricRef.current.add(cloned1);

                  sortLayers(
                    destinyTextureFabric.fabricRef.current,
                    helperLayers[state.fabricSelectedPrintArea]
                  );

                  destinyTextureFabric.fabricRef.current.setActiveObject(
                    cloned1
                  );

                  setState((state) => {
                    return {
                      forceRefreshingToolTipTools:
                        state.forceRefreshingToolTipTools + 1,
                    };
                  });

                  destinyTextureFabric.fabricRef.current.requestRenderAll();
                }

                // ------ Clone element in workspace

                if (state.refFabric.current) {
                  if (printAreaId === state.fabricSelectedPrintArea) {
                    const cloned2 = await cloneElementfromClipboard(
                      copyFabric.elements,
                      obj.id,
                      elementId,
                      0
                      // workspaceWidth / textureSize
                    );

                    cloned2.set({
                      left: coordination.x,
                      top: coordination.y,
                    });

                    if (cloned2) {
                      if (state.refFabric.current.clippingShape) {
                        cloned2.clipPath =
                          state.refFabric.current.clippingShape;
                      }

                      state.refFabric.current.add(cloned2);
                      state.refFabric.current.setActiveObject(cloned2);
                      state.refFabric.current.requestRenderAll();
                      sortLayers(
                        state.refFabric.current,
                        helperLayers[state.fabricSelectedPrintArea]
                      );
                    }
                  }
                }
              }
            }
          });
        } catch (error) {
          console.error('Error cloning object:', error);
        }
      }
    } else if (
      state.workspacesMode.type === enumLayerType.IMPORT_SINGLE_GRAFIC
    ) {
      const findModel = getState().design.productDesignLoadedData.find(
        (item) => item.idVariant === getState().selectedProductOptionsVariantId
      );

      const calibrationValue = findModel.printAreas.find(
        (item) => item.id === printAreaId
      );
      const { maxWidth, maxHeight } = calcMaxElementSize(calibrationValue);

      // ----- Add Layer Object

      const addLayer = {
        id: elementId,
        title: state.workspacesMode.title,
        groupId: printAreaId,
        type: enumLayerType.IMAGE,
        islocked: false,
        visible: true,
        props: {
          url: state.workspacesMode.url,
          placeholder: false,
          originalFile: state.workspacesMode.file
            ? state.workspacesMode.file
            : null,

          srcdst: state.workspacesMode.srcdst,
          srckey: state.workspacesMode.srckey,
        },
      };

      helperInitialLayers.unshift(addLayer);
      helperLayers[printAreaId].unshift(addLayer);

      // ----- Add Workspace Object
      if (state.fabricSelectedPrintArea === printAreaId) {
        if (state.refFabric.current) {
          fabric.Image.fromURL(
            state.workspacesMode.url,
            function (image) {
              const scaleX = maxWidth / image.width;
              const scaleY = maxHeight / image.height;
              const scale = Math.min(scaleX, scaleY);

              const imgObject = image.set({
                left: coordination.x,
                top: coordination.y,
                width: image.width,
                height: image.height,
                angle: 0,
                flipX: false,
                flipY: false,
                centeredScaling: true,
                originX: 'center',
                originY: 'center',
                cornerSize: cornerSize,
                id: elementId,
                type: enumLayerType.IMAGE,
                islocked: false,
                visible: true,
                activeFilters: false,
                filterSettings: {},
                srcdst: state.workspacesMode.srcdst,
                srckey: state.workspacesMode.srckey,
              });
              // Ustawianie skali obrazu
              imgObject.scale(scale);

              if (state.refFabric.current.clippingShape) {
                imgObject.clipPath = state.refFabric.current.clippingShape;
              }

              state.refFabric.current.add(imgObject);
              state.refFabric.current.setActiveObject(imgObject);

              sortLayers(state.refFabric.current, helperLayers[printAreaId]);
            },
            { crossOrigin: 'Anonymous' }
          );
        }
      }

      // ----- Add Texture fabric Object
      for (const textureFabric of state.refTextureFabricArray) {
        if (textureFabric.id === printAreaId) {
          if (textureFabric.fabricRef.current) {
            fabric.Image.fromURL(
              state.workspacesMode.url,
              function (image) {
                const scaleX = maxWidth / image.width;
                const scaleY = maxHeight / image.height;
                const scale = Math.min(scaleX, scaleY);

                const imgObject = image.set({
                  left: coordination.x * (textureSize / workspaceWidth),
                  top: coordination.y * (textureSize / workspaceHeight),
                  width: image.width,
                  height: image.height,
                  scaleX: textureSize / workspaceWidth,
                  scaleY: textureSize / workspaceWidth,
                  angle: 0,
                  flipX: false,
                  flipY: false,
                  centeredScaling: true,
                  originX: 'center',
                  originY: 'center',
                  cornerSize: cornerSize,
                  id: elementId,
                  type: enumLayerType.IMAGE,
                  islocked: false,
                  visible: true,
                  activeFilters: false,
                  filterSettings: {},
                  srcdst: state.workspacesMode.srcdst,
                  srckey: state.workspacesMode.srckey,
                });

                imgObject.scale(scale * (textureSize / workspaceWidth));

                imgObject.setControlsVisibility({
                  mrCustom: false,
                  mbCustom: false,
                  brCustom: false,
                  mtr: false,
                  unlock: false,
                  lock: false,
                  clone: false,
                  deleteControl: false,
                  mtrTexture: true,
                  mrCustomTexture: true,
                  mbCustomTexture: true,
                  brCustomTexture: true,
                  deleteControlTexture: true,
                  cloneTexture: true,
                  lockTexture: true,
                  // unlockTexture: true,
                });

                if (textureFabric.fabricRef.current.clippingShape) {
                  imgObject.clipPath =
                    textureFabric.fabricRef.current.clippingShape;
                }

                textureFabric.fabricRef.current.add(imgObject);
                textureFabric.fabricRef.current.setActiveObject(imgObject);

                sortLayers(
                  textureFabric.fabricRef.current,
                  helperLayers[textureFabric.id]
                );

                setState((state) => {
                  return {
                    forceRefreshingToolTipTools:
                      state.forceRefreshingToolTipTools + 1,
                  };
                });
              },
              { crossOrigin: 'Anonymous' }
            );
          }
          break;
        }
      }
    }

    if (state.refFabric.current) {
      state.refFabric.current.mode = enumWorkspaceModes.STANDARD;

      state.refFabric.current.defaultCursor = 'default';
      state.refFabric.current.selection = true;

      state.refFabric.current.forEachObject(function (object) {
        if (
          object.type === 'SKIP' ||
          object.id === 'workspaceBorder' ||
          object.id.startsWith('clipPath_')
        )
          return;
        object.selectable = true;
        object.evented = true;
      });

      state.refFabric.current.requestRenderAll();
    }

    for (const textureFabric of state.refTextureFabricArray) {
      textureFabric.fabricRef.current.forEachObject(function (object) {
        if (
          object.type === 'SKIP' ||
          object.id === 'workspaceBorder' ||
          object.id.startsWith('clipPath_')
        )
          return;
        object.selectable = true;
        object.evented = true;
      });
      textureFabric.fabricRef.current.requestRenderAll();
    }

    return {
      workspacesMode: { mode: enumWorkspaceModes.STANDARD },

      layers: helperLayers,
      layersInitial: helperInitialLayers,
      selectedLayers: returnSelectedLayers,

      worksapceToolToolTipImageEdit: {
        open: state.workspacesMode.type === enumLayerType.IMAGE ? true : false,
      },

      worksapceToolToolTipTextEdit: {
        open: state.workspacesMode.type === enumLayerType.TEXT ? true : false,
        newObject:
          state.workspacesMode.type === enumLayerType.TEXT ? true : false,
      },
    };
  });
};

export const calcMaxElementSize = (calibrationValue) => {
  let workAreaWidthMm, workAreaHeightMm;

  if (calibrationValue.clipPath.type === 'RECT') {
    workAreaWidthMm = calibrationValue.clipPath.data.width;
    workAreaHeightMm = calibrationValue.clipPath.data.height;
  } else if (calibrationValue.clipPath.type === 'CIRCLE') {
    workAreaWidthMm = calibrationValue.clipPath.data.radius * 2;
    workAreaHeightMm = calibrationValue.clipPath.data.radius * 2;
  } else if (calibrationValue.clipPath.type === 'PATH') {
    const { shape } = calibrationValue.clipPath.data;
    const shapePath = new fabric.Path(shape, {});

    workAreaWidthMm = shapePath.width;
    workAreaHeightMm = shapePath.height;
  } else {
    workAreaWidthMm = 1024;
    workAreaHeightMm = 1024;
  }

  // Obliczenie maksymalnych wymiarów obrazu (30% obszaru roboczego)
  const maxImageWidthPx = workAreaWidthMm * 0.3;
  const maxImageHeightPx = workAreaHeightMm * 0.3;

  return { maxWidth: maxImageWidthPx, maxHeight: maxImageHeightPx };
};

export function calculateMatchingColor(textureHex, darkeningFactor = 0.9) {
  const r = Math.floor(
    (parseInt(textureHex.slice(1, 3), 16) / 255) *
      (parseInt(textureHex.slice(1, 3), 16) / 255) *
      255 *
      darkeningFactor
  );
  const g = Math.floor(
    (parseInt(textureHex.slice(3, 5), 16) / 255) *
      (parseInt(textureHex.slice(3, 5), 16) / 255) *
      255 *
      darkeningFactor
  );
  const b = Math.floor(
    (parseInt(textureHex.slice(5, 7), 16) / 255) *
      (parseInt(textureHex.slice(5, 7), 16) / 255) *
      255 *
      darkeningFactor
  );
  return `#${r.toString(16).padStart(2, '0')}${g
    .toString(16)
    .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
}
